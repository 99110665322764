<template>
  <a-modal
    title="新增角色"
    :width="900"
    :keyboard="false"
    :maskClosable="false"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item label="角色名" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
          <a-input
            placeholder="请输入角色名"
            v-decorator="['name', { rules: [{ required: true, message: '请输入角色名！' }] }]"
          />
        </a-form-item>

        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="排序">
          <a-input-number
            placeholder="请输入排序"
            style="width: 100%"
            v-decorator="['sort', { initialValue: 100 }]"
            :min="1"
            :max="1000"
          />
        </a-form-item>

        <a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
          <a-textarea :rows="4" placeholder="请输入备注" v-decorator="['remark']"></a-textarea>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { sysRoleAdd } from '@/api/modular/system/roleManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import tree from '@/components/Tree/selectPerson1.vue'
export default {
  data() {
    return {
      labelCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 5
        }
      },
      wrapperCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 15
        }
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      typeEnumDataDropDown: [],
      rules: {
        name: [
          { required: true, message: '请输入岗位名称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ]
      },
      filterText: '',
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      checkedPersonList: [], // 右侧已选择人员列表
      propsListData: [], //listData父组件传过来的
      isView: false,
      loading: false,
      treeList: []
    }
  },
  components: {
    tree
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
  created() {
    this.sysDictTypeDropDown()
  },
  methods: {
    // 初始化方法
    add(treeList) {
      this.treeList = treeList
      this.visible = true
    },
    btnClose() {
      this.checkedPersonList = [] // 置空
      this.formVisible = false
    },
    renderTreeContent(h, { node, data }) {
      if (node.level == 0) {
        return <span>{data.name}</span>
      } else {
        if (data.type == 2) {
          return (
            <el-checkbox v-model={node.checked}>
              <span>
                <a-icon type="user" style="color:skyblue;margin-right:10px;" />
                <span>{data.name}</span>
              </span>
            </el-checkbox>
          )
        } else {
          return (
            <span>
              <a-icon type="cluster" style="color:skyblue;margin-right:10px;" />
              <span>{data.name}</span>
            </span>
          )
        }
      }
    },
    // 关键字搜索
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    // node: 当前点击的树节点数据对象，isChecked：是否被勾选
    handleNodeCheckedChange(node, isChecked) {
      if (isChecked && node.children.length == 0 && node.type == 2) {
        // 被勾选，并且当前节点没有子节点，即为叶子节点
        this.checkedPersonList.push(node)
      } else {
        let targetId = node.id
        this.checkedPersonList = this.checkedPersonList.filter(item => item.id !== targetId)
      }
    },
    removeTreeItem(val) {
      this.checkedPersonList = this.checkedPersonList.filter(item => item.id !== val)
      let selectedIds = []
      selectedIds = this.checkedPersonList.map(foo => foo.id)
      this.$refs.tree.setCheckedKeys(selectedIds, true)
    },
    /**
     * 获取字典数据
     */
    sysDictTypeDropDown(text) {
      sysDictTypeDropDown({
        code: 'role_type'
      }).then(res => {
        this.typeEnumDataDropDown = res.data
      })
    },
    handleSubmit() {
      const {
        form: { validateFields }
      } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          sysRoleAdd(values)
            .then(res => {
              if (res.success) {
                this.$message.success('新增成功')
                this.visible = false
                this.confirmLoading = false
                this.$emit('ok', values)
                this.form.resetFields()
              } else {
                this.$message.error('新增失败：' + res.message)
              }
            })
            .finally(res => {
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.form.resetFields()
      this.visible = false
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/common/scss/formDetails.scss';
::v-deep .el-transfer-panel {
  width: 320px;
  height: 370px;
}
.left {
  width: 48%;
  height: 400px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}
.right {
  width: 48%;
  height: 400px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.tree-title {
  font-size: 16px;
  color: black;
  height: 40px;
  background: #d9e2ec;
  display: flex;
  align-items: center;

  div {
    padding-left: 10px;
  }
}
.tree-items {
  height: calc(100% - 40px);
  overflow-y: auto;
  .tree-item {
    padding: 5px 20px;
  }
}
</style>
